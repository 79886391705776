
import _ from 'lodash';
import { mapGetters } from 'vuex';

import uiMixin from '../mixins/ui';
import CompanionCard from './companion-card';
import ResourceValueButtonFull from './resource-value-button-full';

export default {
  name: 'CompanionStatus',
  components: {
    CompanionCard,
    ResourceValueButtonFull,
  },
  mixins: [
    uiMixin,
  ],
  props: {
    name: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      loyaltyProgressColor: '#E9BC54',
      loyaltyProgressEmptyColor: {
        radial: true,
        colors: [
          {
            color: '#000',
            offset: '0',
          },
          {
            color: '#333',
            offset: '100',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('companions', [
      'companionActive',
    ]),
    companion() {
      return this.name
        ? this.$store.getters['companions/getCompanionByName'](this.name)
        : this.companionActive;
    },
    isCompanionActive() {
      return this.companion?.name === this.companionActive?.name;
    },
    resource() {
      return this.companion
        ? this.$store.state.resources.resources[this.companion.loyaltyResourceName]
        : undefined;
    },
    loyalty() {
      let loyalty = 0;

      if (this.companion) {
        loyalty = this.$dayjs(this.companion.expiresAt).diff(this.$store.getters.virtualDay, 'days');
      }

      return loyalty;
    },
    loyaltyDisplay() {
      return `${this.loyalty} ${this.loyalty === 1 ? 'mission' : 'missions'}`;
    },
    loyaltyCost() {
      let c = 0;

      if (this.companion?.loyaltyPerResource) {
        c = 1 / this.companion.loyaltyPerResource;
      }

      return c;
    },
    loyaltyDescription() {
      let str = '';

      if (_.isFunction(this.companion?.loyaltyDescription)) {
        const { label } = this.companion;
        const resourceLabel = this.resource ? this.resource.label.toLowerCase() : '';
        const cost = this.loyaltyCost;
        str = this.companion.loyaltyDescription({
          label,
          resourceLabel,
          cost,
        });
      }

      return str;
    },
    virtualDayProgressPercent() {
      const minutesRemaining = this.$store.state.now.diff(this.$store.getters.virtualDay, 'minutes');
      const minutesPerDay = 1440;

      return Math.floor((minutesRemaining / minutesPerDay) * 100);
    },
    loyaltyProgressPercent() {
      return Math.max(0, Math.min(100, this.virtualDayProgressPercent * (1 / this.loyalty)));
    },
  },
  methods: {
    handleViewAll() {
      this.$store.dispatch('ui/showModal', { name: 'companionsList' });
      this.$emit('close');
    },
  },
};
